.Main {
  background-image: url(OtherImg/BG.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:cover;
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
}

.MainContent{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MainTitle{
  display: flex;
  align-self: center;
  width: 90%;
  margin-bottom: 20%;
}

.MainCenterGroup{ 
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30%;
}

.MainSubtitle{
  display: flex;
  align-self: center;
  width: 85%;
}

.MainBtnGroup{
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-evenly;
}

.MainChangeLangBtn{
  width: 30%;
}

.ImgButtonMain{
  width: 100%
}
/* ============================================================================================================================================ */
.Page2{
  background-image: url(OtherImg/BG.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  width:100%;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.AudioPage{
  height: 70%;
  display: flex;
  justify-content: center;
}

.AudioBtnGroup{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.CHNAudioBtn{
  width: min(70%,300px);
}

.ENGAudioBtn{
  width: min(80%,400px);
}
/* ================================================================================================================================================ */
.header{
  margin-top: 3%;
  margin-right: 5%;
  margin-bottom: 5%;
  display: flex;
  justify-content: flex-end;
  height: 5%;
}

.Logo{
  height: 100%;
}

.footer{
  margin-bottom: min(5%,20px);
  margin-right: min(2.5%, 10px);
  margin-top: 10%;
  display: flex;
  justify-content: flex-end; 
  height: 10%;
}

.Page2ChangeLangBtn{
  height: 100%;
}
/* ===================================================================================================================================================== */
.orientationCover {
  background-image: url(OtherImg/BG.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:cover;
  position: absolute;
  width: 100%;
  height: 150%;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.orientationImg{
  width: 30%;
  display: flex;
  align-self: center;
}
